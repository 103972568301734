<template>
  <div>
    <!--Content-->
    <GeneralJournalEntriesForm
      :value="transaction"
      @passBack="$emit('passBack', $event)"
    />
  </div>
</template>
<script>
export default {
  name: "GeneralJournalEntriesWrapper",
  props: ["transaction"],
  components: {
    GeneralJournalEntriesForm: () => import("./GeneralJournalEntriesForm.vue"),
  },
  data() {
    return {};
  },
};
</script>